<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Testimonial</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="testimonial-form">
          <v-text-field
            label="Title *"
            v-model="fields.title"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('title')"
            :error-messages="errors['title']"
          ></v-text-field>
          <v-textarea
            label="Body *"
            v-model="fields.body"
            rows="3"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('body')"
            :error-messages="errors['body']"
          ></v-textarea>
          <v-text-field
            label="By *"
            v-model="fields.by"
            type="text"
            outlined
            dense
            background-color="white"
            :error="errors.hasOwnProperty('by')"
            :error-messages="errors['by']"
          ></v-text-field>
          <v-file-input
            v-model="fields.image"
            accept="image/png"
            placeholder="Choose a Illustration Image"
            prepend-icon="mdi-camera"
            label="Image *"
            outlined
            background-color="white"
            @change="imageHasChanged()"
            :error="errors.hasOwnProperty('image')"
            :error-messages="errors['image']"
          ></v-file-input>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="testimonial-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      fields: {
        title: null,
        body: null,
        by: null,
        image: null,
        image_has_changed: false,
      },
      testimonial: null,
      errors: {},
    };
  },

  methods: {
    openForm: function(testimonial = null) {
      if (testimonial !== null) {
        this.isEditing = true;
        this.testimonial = testimonial;
        this.fields.title = testimonial.title;
        this.fields.body = testimonial.body;
        this.fields.by = testimonial.by;
        this.fields.image = testimonial.image
          ? new File([testimonial.image], testimonial.image)
          : null;
      }

      this.dialog = true;
    },

    resetForm: function() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.testimonial = null;
      this.fields.title = null;
      this.fields.body = null;
      this.fields.by = null;
      this.fields.image = null;
      this.fields.image_has_changed = false;
      this.errors = {};
    },

    saveForm: function() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.testimonialId = this.testimonial.id;
      }

      this.$store
        .dispatch("watson/testimonials/saveTestimonial", payload)
        .then(() => {
          this.resetForm();
        })
        .catch(err => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    imageHasChanged: function() {
      this.fields.image_has_changed = true;
    },
  },
};
</script>
